<template>

<app-content :centralise="true" :fullheight="true" class="content">

	<h1>Hi, {{ firstname }}!<small>Select an organization or convention to get started.</small></h1>

	<div class="cards">

		<app-card v-for="(card, index) in cards" :to="card.to" :imageIcon="card.type" :borderless="true" :key="index" :date="card.date" :image="card.image" :fitImage="true" :title="card.title" :subtitle="card.subtitle" class="card" />

	</div>

</app-content>

</template>

<script>

export default {

	computed: {

		firstname: function() {

			return this.$store.getters['session/firstname']

		},

		cards: function() {

			var cards = []

			var permittedOrgs = this.$_.pluck(this.$_.filter(this.$store.getters['session/teams'], function(team) {

				return team.type !== this.$constants.team.members.type.convention

			}.bind(this)), 'organisation')

			this.$_.each(this.$store.getters['session/organisations'], function(org) {

				if (this.$_.contains(permittedOrgs, org.id)) {

					cards.push({
						type: 'organisation',
						image: org.logo,
						title: org.name,
						subtitle: 'Organization',
						to: {
							name: 'Organisation.Dashboard',
							params: {
								organisation: org.slug
							}
						}
					})

				}

			}.bind(this))

			this.$_.each(this.$store.getters['session/conventions'], function(con) {

				if (con.start >= this.$time) {

					var org = this.$_.findWhere(this.$store.getters['session/organisations'], {
						id: con.organisation
					})

					cards.push({
						type: 'convention',
						image: con.logo,
						title: con.fullname,
						subtitle: 'Convention',
						date: con.start,
						to: {
							name: 'Convention.Dashboard',
							params: {
								organisation: org.slug,
								convention: con.slug
							}
						}
					})
					
				}

			}.bind(this))

			return cards

		}

	}

}

</script>

<style scoped>

.content {
	background-color: #1e3054;
	flex-direction: column!important;
}

.content h1 {
	color: #fff;
	font-size: 32px;
	margin-bottom: 40px;
	text-align: center;
}

.content h1 small {
	display: block;
	font-size: 16px;
	margin-top: 4px;
	opacity: 0.5;
}

.cards {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
}

.card {
	width: 240px;
	margin: 10px;
	transition: transform 100ms linear;
}

.is-desktop .card:hover {
	transform: scale(1.05);
}

</style>